@charset "UTF-8";
:global(.el-dialog.cDialogPaySuccessImg) {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border-radius: 5px !important;
  text-align: center;
  width: auto;
  background: none;
  padding: 0;
  margin: 0;
  box-shadow: none; }
  :global(.el-dialog.cDialogPaySuccessImg) :global(.el-dialog__header) {
    height: 0;
    padding: 0; }
    :global(.el-dialog.cDialogPaySuccessImg) :global(.el-dialog__header) :global(.el-dialog__headerbtn) {
      top: 6.25vw;
      right: 6.25vw;
      position: fixed;
      z-index: 1000; }
      :global(.el-dialog.cDialogPaySuccessImg) :global(.el-dialog__header) :global(.el-dialog__headerbtn) :global(.el-dialog__close)::before {
        content: '×';
        width: 100%;
        height: 100%;
        display: block;
        cursor: pointer;
        font-size: 34px;
        font-weight: 500;
        background: none;
        color: rgba(255, 255, 255, 0.6); }
      :global(.el-dialog.cDialogPaySuccessImg) :global(.el-dialog__header) :global(.el-dialog__headerbtn):hover :global(.el-dialog__close)::before {
        color: white; }
  :global(.el-dialog.cDialogPaySuccessImg) :global(.el-dialog__body) {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  :global(.el-dialog.cDialogPaySuccessImg) .CenterPromptbox {
    padding-bottom: 68px; }

.pay-success {
  display: flex; }
  .pay-success .pay-success-img {
    max-width: 100vw;
    max-height: 100vh;
    object-fit: contain; }
